/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable import/extensions */

import { h, Component } from 'preact';
import { connect } from 'unistore/preact';
import { imageUrl } from '_helpers';
import { Stories } from '_components/grid';
import style from './style';

class PostContent extends Component {
	componentDidMount = () => {
	  console.warn('PostContent data >>', this.props);
	};

	componentDidUpdate = () => {
	  console.warn('PostContent data >>', this.props);
	};

	parseDate = (date) => {
	  const d = new Date(date);
	  let month = `${d.getMonth() + 1}`;
	  let day = `${d.getDate()}`;
	  const year = d.getFullYear();

	  if (month.length < 2) month = `0${month}`;
	  if (day.length < 2) day = `0${day}`;

	  return [year, month, day].join('/');
	};

	getPostTags = (post) => {
	  const tags = [];
	  if (post && post._nc_m2m_Posts_Tags) {
	    post._nc_m2m_Posts_Tags.forEach((tag) => {
	      tags.push(tag.Tags.name);
	    });
	    return tags.join(',');
	  }
	  return '';
	};

	getUrlParameter = (name) => {
	  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
	  const results = regex.exec(location);
	  return results === null
	    ? ''
	    : decodeURIComponent(results[1].replace(/\+/g, ' '));
	};

	render = () => {
	  const id = this.getUrlParameter('id');
	  return (
	    <div className={`${style.postcon}`}>
	      <div className={`${style.container}`}>
	        {this.props.posts.data
	          ? this.props.posts.data.map((post) => {
	            if (post.Id === parseInt(id)) {
	              const { content } = post;
	              return (
	                <div className={style.left}>
	                  <div className={style.title}>
	                    <h1>{post.title}</h1>
	                    <span>{this.parseDate(`${post.CreatedAt}`)}</span>
	                    <div className={style.tags}>
	                      {post._nc_m2m_Posts_Tags.map((tag) => {
	                        return <span>{tag.Tags.name}</span>;
	                      })}
	                    </div>
	                  </div>
	                  <div className={`${style.content}`}>
	                    <div>
	                      {post.image && (
	                        <img
	                          src={imageUrl(post.image)}
	                          alt={post.title ? post.title : ''}
	                        />
	                      )}
	                      <div dangerouslySetInnerHTML={{ __html: content }} />
	                    </div>
	                  </div>
	                </div>
	              );
	            }
	            return null;
						  })
	          : null}
	        <div className={style.right}>
	          <p>{this.props.stories.title ? this.props.stories.title : ''}</p>
	          <Stories
	            itemsToDisplay={this.props.stories.itemsToDisplay}
	            displayTags={this.props.stories.displayTags}
	            button={this.props.button}
	          />
	        </div>
	      </div>
	    </div>
	  );
	};
}

const PostContentComp = connect(['posts', 'tags'])(PostContent);
export default PostContentComp;
