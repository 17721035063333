/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */

import { h, Component } from 'preact';
import { connect } from 'unistore/preact';
import { imageUrl } from '_helpers';
import style from './style';

class FeaturedArticles extends Component {
  constructor (props) {
    super(props);
    this.state = {
      currentPage: 0
    };
  }

	handlePageChange = (pageIndex) => {
	  this.setState({ currentPage: pageIndex });
	};

	render () {
	  const { posts } = this.props;
	  const { currentPage } = this.state;

	  const featuredArticles = posts.data
	    ? posts.data.filter((article) => article.isFeatured)
	    : [];

	  const articlesPerPage = 1; // Display one article at a time

	  const totalPages = Math.ceil(featuredArticles.length / articlesPerPage);
	  const displayedArticles = featuredArticles.slice(
	    currentPage * articlesPerPage,
	    (currentPage + 1) * articlesPerPage
	  );

	  return (
	    <div className={`${style.featuredArticles}`}>
	      <div className={`${style.container}`}>
	        <div className={`${style.articleContainer}`}>
	          {displayedArticles.length > 0 &&
							displayedArticles.map((article, i) => {
							  return (
							    <div className={`${style.article}`} key={i}>
							      {article.image ? (
							        <a
							          href={`/post?id=${article.Id}`}
							          target={article.openNewTab ? '_blank' : '_self'}
							          rel="noreferrer"
							        >
							          <img
							            className={`${style.articleimg}`}
							            src={article.image ? imageUrl(article.image) : ''}
							            alt={article.title || 'Featured article image'}
							            aria-label={article.title || 'Featured article image'}
							          />
							        </a>
							      ) : null}
							      <div className={`${style.articleContent}`}>
							        <div className={`${style.left}`}>
							          {article.title ? <h1>{article.title}</h1> : null}
							          {article.shortDescription ? (
							            <p>{article.shortDescription}</p>
							          ) : article.content ? (
							            <p>{article.content}</p>
							          ) : null}
							        </div>
							        <div className={`${style.right}`}>
							          <div className={`${style.tags}`}>
							            {article._nc_m2m_Posts_Tags
							              ? article._nc_m2m_Posts_Tags.map((tag, i) => {
							                return (
							                  <button
							                    type="button"
							                    className={`${style.tag}`}
							                    key={i}
							                  >
							                    {tag.Tags.name}
							                  </button>
							                );
														  })
							              : null}
							          </div>

							          <a
							            href={`/post?id=${article.Id}`}
							            target={article.openNewTab ? '_blank' : '_self'}
							            rel="noreferrer"
							          >
							            {article.linkText}
							          </a>
							        </div>
							      </div>
							    </div>
							  );
							})}

	          {/* Pagination Dots */}
	          <div className={`${style.pagination}`}>
	            {Array.from({ length: totalPages }).map((_, index) => (
	              <button
	                key={index}
	                className={`${style.pageDot} ${
	                  index === currentPage ? style.activeDot : ''
	                }`}
	                onClick={() => this.handlePageChange(index)}
	              />
	            ))}
	          </div>
	        </div>
	      </div>
	    </div>
	  );
	}
}

const ConnectComp = connect(['posts'])(FeaturedArticles);
export default ConnectComp;
