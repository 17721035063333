import { store } from '_unistore';

// eslint-disable-next-line import/prefer-default-export
export function getTranslation (key, defaultWord) {
  const {
    translations
    // selelectedLanguage
  } = store.getState();

  try {
    // if (!selectedLanguage) {
    //   return '';
    // } else if (!translations.data) {
    //   return hardCodedTranslations[selectedLanguage][key] || defaultWord || key;
    // }

    return (
      translations.data.Contents[key] ||
			translations.data.Messages[key] ||
			translations.data.Rewards[key] ||
			defaultWord ||
			key
    );
  } catch (err) {
    // eslint-disable-next-line
		console.log(`SPA >> getTranslation ${err.message}`, err);
    return defaultWord || key;
  }
}
