/* eslint-disable import/extensions */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { h, Component } from 'preact';
import { connect } from 'unistore/preact';
import { imageUrl } from '_helpers';
import style from './style';

class Stories extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedTag: 'All'
    };
  }

	componentDidMount = () => {};

	componentDidUpdate = () => {
	  this.displayTaggedItems(this.state.selectedTag);
	};

	parseDate = (date) => {
	  const d = new Date(date);
	  let month = `${d.getMonth() + 1}`;
	  let day = `${d.getDate()}`;
	  const year = d.getFullYear();

	  if (month.length < 2) month = `0${month}`;
	  if (day.length < 2) day = `0${day}`;

	  return [year, month, day].join('/');
	};

	getPostTags = (post) => {
	  const tags = [];
	  if (post && post._nc_m2m_Posts_Tags) {
	    post._nc_m2m_Posts_Tags.forEach((tag) => {
	      tags.push(tag.Tags.name);
	    });
	    return tags.join(',');
	  }
	  return '';
	};

	selectTag = (tag) => {
	  this.setState(
	    {
	      selectedTag: tag
	    },
	    () => {
	      this.displayTaggedItems(tag);
	    }
	  );
	};

	displayTaggedItems = (tag) => {
	  try {
	    const elem = document.querySelectorAll('[data-post]');
	    elem.forEach((item) => {
	      const itemTags = item.dataset.tags.split(',');
	      if (itemTags.indexOf(tag) > -1) {
	        item.style.display = 'inline-block';
	      } else {
	        item.style.display = 'none';
	      }
	    });
	  } catch (err) {
	    console.error('displayTaggedItems', err);
	  }
	};

	render = () => {
	  return (
	    <div className={`${style.stories}`}>
	      <div className={`${style.container}`}>
	        {this.props.displayTags ? (
	          <div className={`${style.tags}`}>
	            {this.props.tags.data
	              ? this.props.tags.data.map((tag) => {
	                return (
	                  <button
	                    type="button"
	                    onClick={() => this.selectTag(tag.name)}
	                    style={
	                      this.state.selectedTag === tag.name
	                        ? 'opacity:1; background:#4a8eb9'
	                        : ''
	                    }
	                  >
	                    {tag.name}
	                  </button>
	                );
								  })
	              : null}
	          </div>
	        ) : null}
	        <div>
	          {this.props.posts.data
	            ? this.props.posts.data.map((post, i) => {
	              if (i + 1 <= this.props.itemsToDisplay) {
	                return (
	                  <div
	                    className={`${style.postitem}`}
	                    data-tags={this.getPostTags(post)}
	                    data-post
	                  >
	                    <a
	                      href={`/post?id=${post.Id}`}
	                      target={post.openNewTab ? '_blank' : '_self'}
	                      rel="noreferrer"
	                    >
	                      {post.image ? (
	                        <div
	                          className={`${style.postimage}`}
	                          style={
	                            post.image
	                              ? `background-image:url(${imageUrl(
	                                post.image
																	  )})`
	                              : `background-image:url(${imageUrl(
	                                '5bf8f7254a8045bf9390a57c964a28a2.jpg'
																	  )})`
	                          }
	                        />
	                      ) : null}

	                      <div className={`${style.postdetail}`}>
	                        <p>{post.title}</p>
	                        <span>{this.parseDate(post.CreatedAt)}</span>
	                      </div>
	                    </a>
	                  </div>
	                );
	              }
	              return null;
							  })
	            : null}
	        </div>
	        {this.props.button
	          ? this.props.button.map((btn) => {
	            return (
	              <a
	                className={`${style.btnmore}`}
	                href={btn.link.url}
	                target={btn.link.openInNewTab ? '_blank' : '_self'}
	                rel="noreferrer"
	              >
	                {btn.text}
	              </a>
	            );
						  })
	          : null}
	      </div>
	    </div>
	  );
	};
}

const StoriesComp = connect(['posts', 'tags'])(Stories);
export default StoriesComp;
