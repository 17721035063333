import { updateStore } from '_unistore';

export const supportedLanguages = [
  {
    Name: 'English',
    langCode: 'en-us',
    langAlias: 'en',
    currencyCode: 'USD',
    flag: 'flags/en.png',
    codes: ['en', 'en-us']
  },
  {
    Name: '简体中文',
    langCode: 'zh-cn',
    langAlias: 'cn',
    currencyCode: 'RMB',
    flag: 'flags/cn.png',
    codes: ['zh', 'cn', 'zh-cn']
  },
  {
    Name: 'Bhs Indonesia',
    langCode: 'id-id',
    langAlias: 'id',
    currencyCode: 'IDR',
    flag: 'flags/id.png',
    codes: ['id', 'in-id', 'in', 'id-id']
  },
  {
    Name: 'India',
    langCode: 'en-in',
    langAlias: 'in',
    currencyCode: 'INR',
    flag: 'flags/in.png',
    codes: ['en-in']
  },
  {
    Name: 'ខ្មែរ',
    langCode: 'km-kh',
    langAlias: 'kh',
    currencyCode: 'MYR',
    flag: 'flags/kh.png',
    codes: ['kh', 'km', 'km-kh']
  },
  {
    Name: '한국어',
    langCode: 'ko-kr',
    langAlias: 'kr',
    currencyCode: 'KRW',
    flag: 'flags/kr.png',
    codes: ['ko', 'kr', 'ko-kr']
  },
  {
    Name: 'ไทย',
    langCode: 'th-th',
    langAlias: 'th',
    currencyCode: 'THB',
    flag: 'flags/th.png',
    codes: ['th', 'th-th']
  },
  {
    Name: 'Tiếng Việt',
    langCode: 'vi-vn',
    langAlias: 'vn',
    currencyCode: 'VND',
    flag: 'flags/vn.png',
    codes: ['vi', 'vn', 'vi-vn']
  }
  // {
  //   Name: 'English (Australia)',
  //   langCode: 'en-au',
  //   langAlias: 'au',
  //   currencyCode: 'AUD',
  //   flag: 'flags/au.png',
  //   codes: ['au', 'en-au']
  // },
  // {
  //   Name: 'Español (Mexico)',
  //   langCode: 'es-mx',
  //   langAlias: 'es',
  //   currencyCode: 'MXN',
  //   flag: 'flags/es.png',
  //   codes: ['es', 'mx', 'es-mx']
  // },
  // {
  //   Name: 'Español (Peru)',
  //   langCode: 'es-pe',
  //   langAlias: 'pe',
  //   currencyCode: 'PEN',
  //   flag: 'flags/pe.png',
  //   codes: ['pe', 'es-pe']
  // },
  // {
  //   Name: 'Español (Chile)',
  //   langCode: 'es-cl',
  //   langAlias: 'cl',
  //   currencyCode: 'CLP',
  //   flag: 'flags/cl.png',
  //   codes: ['cl', 'es-cl']
  // }
];

// eslint-disable-next-line import/prefer-default-export
export function updateLanguage (langAlias) {
  if (langAlias) {
    updateStore({
      selectedLanguage: langAlias
    });
    // eslint-disable-next-line
		console.log(
      `SPA >> (mutations/language.js) >> Updated app language to ${langAlias}`
    );
  }
}

export function getDefaultLanguage () {
  try {
    /**
		 * 1. from lang browser lang code
		 * 2. from lang browser country code
		 * 3. default = en
		 */
    let langAlias = '';

    // 1. from lang browser lang code
    let fromBrowser = window.navigator.language || '';
    if (!langAlias && fromBrowser) {
      // eslint-disable-next-line no-use-before-define
      langAlias = getLanguageAlias(fromBrowser);
    }

    // 2. from lang browser country code
    if (!langAlias && fromBrowser) {
      fromBrowser = fromBrowser.split('-').map((i) => i.toLowerCase());
      const langBrowser =
				fromBrowser.length > 1 && ['in', 'au'].indexOf(fromBrowser[1]) > -1
				  ? fromBrowser[1]
				  : fromBrowser[0];
      // eslint-disable-next-line no-use-before-define
      langAlias = getLanguageAlias(langBrowser);
    }

    // 3. default = en
    if (!langAlias && fromBrowser) {
      langAlias = 'en';
    }

    return langAlias;
  } catch (err) {
    return 'en';
  }
}

export function getLanguageInfoByAlias (language) {
  return supportedLanguages.find(
    (i) => i.langAlias === (language || '').toLowerCase()
  );
}

export function getLanguageCode (language) {
  const lang = supportedLanguages.find(
    (i) => i.codes.indexOf((language || '').toLowerCase()) > -1
  );
  return lang && lang.langCode;
}

export function getLanguageAlias (language) {
  const lang = supportedLanguages.find(
    (i) => i.codes.indexOf((language || '').toLowerCase()) > -1
  );
  return lang && lang.langAlias;
}
