// eslint-disable-next-line import/prefer-default-export
export const initialStore = {
  // project data
  translations: {
    fetching: false,
    result: false,
    data: null
  },
  selectedLanguage: null,
  grid: {
    fetching: false,
    result: false,
    data: []
  },
  menu: {
    fetching: false,
    result: false,
    data: null
  },
  posts: {
    fetching: false,
    result: false,
    data: null
  },
  featuredPosts: {
    fetching: false,
    result: false,
    data: null
  },
  tags: {
    fetching: false,
    result: false,
    data: null
  },
  liveChatLink: null,
  popupModule: {
    fetching: false,
    result: false,
    data: []
  },
  // user
  authUser: null,
  // modals
  promptModal: null,
  messageModal: null,
  componentModal: null,
  pageLoader: {
    display: false
  }
};
