import {
  setCookie,
  getLanguageInfoByAlias,
  clearFwc,
  prefetch
} from '_helpers';
import { store, updateStore } from '_unistore';

export function checkIfLangIsSupported (lang) {
  const globalStore = store.getState();
  // eslint-disable-next-line prefer-destructuring
  const languages = globalStore.languages;
  const supportedLangs = [];
  if (languages.data) {
    languages.data.map((language) => supportedLangs.push(language.code));
    return supportedLangs.indexOf(lang) > 0;
  }
  return false;
}

export function setLanguage (languageAlias) {
  // eslint-disable-next-line no-console
  console.log(`SPA >> setLanguage ${languageAlias}`);
  const language = getLanguageInfoByAlias(languageAlias) || {};

  setCookie('language', language.langCode);
  clearFwc();
  window.localStorage.clear();

  updateStore({
    selectedLanguage: languageAlias.toLowerCase()
  });

  prefetch();
}
