import { store, updateStore } from '_unistore';
import { xhr, urlTranslation } from '_helpers';

// eslint-disable-next-line import/prefer-default-export
export function fetchTranslation () {
  return new Promise((resolve) => {
    // curreny state
    const { translations } = store.getState();
    // initial state
    updateStore({
      translations: {
        ...translations,
        fetching: true,
        result: false
      }
    });

    xhr(urlTranslation)
      .then((res) => {
        updateStore({
          translations: {
            data: res.data,
            fetching: false,
            result: true
          }
        });
        resolve(true);
      })
      .catch(() => {
        updateStore({
          translations: {
            ...translations,
            fetching: false,
            result: false
          }
        });
        resolve(false);
      });
  });
}
