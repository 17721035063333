/* eslint-disable import/extensions */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { h, Component } from 'preact';
import { connect } from 'unistore/preact';
import {
  imageUrl,
  supportedLanguages,
  getLanguageCode,
  getLanguageAlias
} from '_helpers';
import { setLanguage } from '_mutations';
import style from './style';

class LanguageSwitch extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeLanguage: null,
      openSelect: false
    };
  }

	componentDidMount = () => {
	  const { selectedLanguage } = this.props;
	  this.setState({
	    activeLanguage: getLanguageCode(selectedLanguage)
	  });
	};

	openLangSelect = () => {
	  this.setState((prevState) => ({
	    openSelect: !prevState.openSelect
	  }));
	};

	changeLanguage = (langCode) => {
	  setLanguage(getLanguageAlias(langCode));
	  this.setState({
	    activeLanguage: langCode,
	    openSelect: false
	  });
	};

	render () {
	  const { activeLanguage, openSelect } = this.state;

	  return (
	    <div data-type="langswitch" className={style.langswitch}>
	      <div
	        className={style.drop}
	        onClick={this.openLangSelect}
	        style={{
	          backgroundImage: `url(${imageUrl(
							supportedLanguages.find(
							  (lang) => lang.langCode === activeLanguage
							)?.flag
	          )})`
	        }}
	      />
	      <div
	        className={style.langdropdown}
	        style={{
	          maxHeight: openSelect ? '700px' : '0px',
	          borderTopColor: openSelect ? '#fff' : 'transparent'
	        }}
	      >
	        {supportedLanguages.map((item) => (
	          <span
	            key={item.langCode}
	            onClick={() => this.changeLanguage(item.langCode)}
	            data-lang={item.langCode}
	            style={{
	              backgroundImage: `url(${imageUrl(item.flag)})`,
	              border:
									activeLanguage === item.langCode
									  ? '2px solid #007BFF'
									  : 'none'
	            }}
	          />
	        ))}
	      </div>
	    </div>
	  );
	}
}

const LangComp = connect(['selectedLanguage'])(LanguageSwitch);
export default LangComp;
